import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function User() {
  return (
    <>
      <Container fluid>
      <div class="row d-flex justify-content-center">
      <Col lg="10" md="12" sm="12">
        <Row>
          <Col md="12">
          <h2>¿Qué es un Atlas de Riesgos?</h2>
        <p className="description">De acuerdo con la Ley de Gestión Integral de Riesgos y Protección Civil
        de la Ciudad de México (LGIRPC) en su Artículo 2 fracción V, el Atlas de Riesgos es un: Sistema
         integral de información de la Ciudad de México,
        que conjunta los Atlas de Riesgos de las Alcaldías, sobre los daños y pérdidas esperados,
        resultado de un análisis espacial y temporal, sobre la interacción entre los Peligros,
        la Vulnerabilidad, la Exposición y los Sistemas Expuestos.</p>
        <h2>¿Cómo se integra el Atlas de Riesgos de la Cuidad de México?</h2>
        <p className="description">De acuerdo con la LGIRPC en su artículo 83:
        El Atlas de Riesgos de la Ciudad de México, estará conformado por distintas capas de información,
        mismas que estarán clasificadas en los términos de la Ley en materia de acceso a la información pública para su consulta.
Los Atlas de Riesgos pueden contener:
mapas de peligros por fenómenos perturbadores,
mapas de susceptibilidad,
inventario de bienes expuestos,
inventario de vulnerabilidades,
mapas de riesgos,
escenarios de riesgos.</p>
<p className="description">
La información deberá ser integrada y actualizada en el Atlas de Riesgos con lo siguiente:
información generada por las áreas internas de la Secretaría;
la información contenida en los Atlas de las Alcaldías;
toda información del sector público y privado que sea relevante para su integración en el Atlas de Riesgos y
los resultados de los estudios geológicos, geotécnicos, geofísicos, hidrológicos y los que pudieran ser necesarios para conocer el nivel de riesgo
o peligro asociado a cada fenómeno perturbador que pueda afectar a la Ciudad, realizados por instituciones académicas, sector público y privado.
</p>
<h2>¿Cómo se elaboran los Atlas de Riesgos?</h2>
<p className="description">
Los Atlas de Riesgos deben cumplir con lo estipulado en la Guía de contenido Mínimo publicada en el Diario Oficial, con la finalidad de promover y
consolidar la elaboración de un Atlas Nacional de Riesgos a nivel federal, estatal y municipal, asegurando su homogeneidad.
Los Atlas de Riesgos de las Alcaldías, deben elaborarse y actualizarse de acuerdo con los lineamientos emitidos por la Secretaría.
</p>
        </Col>
        </Row>
        <Row>
          <div class="row d-flex justify-content-center">
          <Col md="3">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={
                    require("assets/img/cdmx_aerea3.jpg")
                      .default
                  }
                ></img>
              </div>
              <Card.Body>
                <div className="author">
                <a href="https://www.atlas.cdmx.gob.mx/pdf/LEARA.pdf"
                  target="_blank">
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/dw.png").default}
                    ></img>
                    </a>
                    <h5 className="title">Lineamientos para la elaboración de los
                    Atlas de Riesgos de las Alcaldías</h5>
                  <p className="description">Publicados en gaceta el 26 de agosto de 2019 </p>

                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={
                    require("assets/img/cdmx_aerea3.jpg")
                      .default
                  }
                ></img>
              </div>
              <Card.Body>
                <div className="author">
                <a href="https://www.atlas.cdmx.gob.mx/pdf/LAARA.pdf"
                  target="_blank">
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/dw.png").default}
                    ></img>
                    </a>
                    <h5 className="title">Lineamientos para la actualización de los
                    Atlas de Riesgos de las Alcaldías</h5>
                  <p className="description">Publicados en gaceta el 26 de diciembre de 2019 </p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          </div>
        </Row>
        </Col>
            </div>
      </Container>
    </>
  );
}

export default User;
