import React from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Carousel
} from "react-bootstrap";

function alcaldias() {

  return (

location.assign('https://serviciosatlas.sgirpc.cdmx.gob.mx/portal/apps/sites/#/alcaldias')

  );
}

export default alcaldias;
