
import React, { Component } from "react";
import { Container } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer px-0 px-lg-3">
        <Container fluid>
          <nav>
            <p className="copyright text-center">
              © {new Date().getFullYear()}{" "}
              <a href="https://www.proteccioncivil.cdmx.gob.mx/">Secretaría de Gestión Integral de Riesgos y Protección Civil</a>,
              Gobierno de la Ciudad de México
            </p>
            <span id="siteseal"><script async type="text/javascript"
            src="https://seal.godaddy.com/getSeal?sealID=vJ1UMjISB5GZ2zcVDxO0VjmxENMaiS9BSYoBcoJkTryPdLki3gn9WslHcWaq">
            </script></span>
          </nav>
        </Container>
      </footer>
    );
  }
}

export default Footer;
