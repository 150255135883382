
import Inicio from "views/Inicio.js";
import acerca from "views/acerca.js";
import alcaldias from "views/alcaldias.js";
import buscar from "views/buscar.js";
import modulos from "views/modulos.js";
import Maps from "views/Maps.js";


const dashboardRoutes = [
  {
    path: "/inicio",
    name: "Inicio",
    icon: "nc-icon nc-button-power",
    component: Inicio,
    layout: "/principal",
  },
  {
    path: "/acerca",
    name: "Acerca del Atlas",
    icon: "nc-icon nc-paper-2",
    component: acerca,
    layout: "/principal",
  },
  {
    path: "/alcaldias",
    name: "Alcaldías",
    icon: "nc-icon nc-bank",
    component: alcaldias,
    layout: "/principal",
  },
  /*!
  {
    path: "/buscar",
    name: "Búsqueda de capas",
    icon: "nc-icon nc-zoom-split",
    component: buscar,
    layout: "/Nu3v0/principal",
  },
  */
  {
    path: "/modulos",
    name: "Módulos",
    icon: "nc-icon nc-settings-gear-64",
    component: modulos,
    layout: "/principal",
  },
  {
    path: "/descargas",
    name: "Descargas",
    icon: "nc-icon nc-cloud-download-93",
    component: Maps,
    layout: "/principal",
  },
];

export default dashboardRoutes;
