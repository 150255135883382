import React from "react";

// react-bootstrap components
import { Badge, Button, Navbar, Nav, Container } from "react-bootstrap";

function Maps() {

  return (
location.assign('https://www.atlas.cdmx.gob.mx/datosabiertos2.html')
  );
}

export default Maps;
